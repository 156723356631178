<template>
    <component :is="customTag ?? 'span'" v-if="useRuntimeConfig().public.enableSmartLinks" v-bind:[atrname]="itemId"
        :id="id">
        <slot></slot>
    </component>
    <template v-else>
        <component :is="customTag ?? 'span'" :id="id">
            <slot></slot>
        </component>
    </template>
</template>
<script setup lang="ts">
const props = defineProps<{ id: string, component: boolean, customTag?: string }>()

const itemId = computed(() => props.id.replace('_', ''))

const emits = defineEmits(['onClickAction'])
const atrname = props.component ? 'data-kontent-component-id' : 'data-kontent-item-id';
</script>