import { contentTypes } from '~/models'
import { LazyAnchorTag, LazyBanner, LazyBasicContentSection, LazyCallToAction, LazyCardCollection, LazyCardItem, LazyContactBlock, LazyHomeHero, LazyImageBlock, LazyInteriorHero, LazyNavigationLink, LazyPublicationList, LazySplitContentBlock, LazySplitContentBlockBasic, LazySplitContentBlockImage, LazySplitContentBlockPublicationList } from '#components'

// Define the mapping of content types to their respective components
const components: { [key: string]: any } = {
  // Blocks
  [contentTypes.card_item.codename]: LazyCardItem,
  [contentTypes.split_content_block_basic_content.codename]: LazySplitContentBlockBasic,
  [contentTypes.split_content_block_image.codename]: LazySplitContentBlockImage,
  [contentTypes.publication_list.codename]: LazyPublicationList,
  [contentTypes.banner.codename]: LazyBanner,
  [contentTypes.basic_content_section.codename]: LazyBasicContentSection,
  [contentTypes.contact_block.codename]: LazyContactBlock,
  [contentTypes.image_block.codename]: LazyImageBlock,

  // puzzles
  [contentTypes.call_to_action.codename]: LazyCallToAction,
  [contentTypes.card_collection.codename]: LazyCardCollection,
  [contentTypes.split_content_block.codename]: LazySplitContentBlock,
  [contentTypes.split_content_block_publication_list.codename]: LazySplitContentBlockPublicationList,

  // hero
  [contentTypes.interior_hero.codename]: LazyInteriorHero,
  [contentTypes.home_hero.codename]: LazyHomeHero,

  // Rich Text
  [contentTypes._navigation_link.codename]: LazyNavigationLink,
  [contentTypes.anchor_tag.codename]: LazyAnchorTag,


}

export default function (type: string) {
  return components[type]
}
