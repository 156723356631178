import { type IContentItem, type Elements } from '@kontent-ai/delivery-sdk'
export const isEmpty = (value: string | undefined) => {
  if (!value) return true
  return value == '<p><br></p>'
}

export const multipleChoiceToBoolean = (element: Elements.MultipleChoiceElement) => {
  return element.value.length > 0 ? element.value[0].codename == 'yes' ||
                                    element.value[0].codename == 'show'||
                                    element.value[0].codename == 'expanded': false
}
