<template>
    <SmartLinkItemComponent custom-tag="div" class="interior-hero" v-if="model" :id="`_${model.system.id}`"
        :component="!model.system.workflow">
        <div class="container">
            <div class="row">
                <div class="col-lg">
                    <SmartLinkStandaloneElement custom-tag="h6" class="eyebrow" v-if="model.elements.eyebrow.value"
                        :codename="type.elements.eyebrow.codename">
                        {{ model.elements.eyebrow.value }}
                    </SmartLinkStandaloneElement>
                    <SmartLinkStandaloneElement custom-tag="h1" class="title" :codename="type.elements.title.codename">
                        {{ model.elements.title.value }}
                    </SmartLinkStandaloneElement>
                </div>
            </div>
        </div>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import { contentTypes, type CallToActionModel, type InteriorHeroModel } from '~/models';

const pageStore = usePageStore();
const type = contentTypes.home_hero;
const props = defineProps<{ model: InteriorHeroModel }>();

//console.log(props.model);
</script>