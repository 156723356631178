<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr v-for="item in headers">
                    <th v-for="cell in item.cells">
                        <template v-for="textItem in cell.content">
                            <PortableText :value="textItem" :components="components" />
                        </template>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in rows">
                    <td v-for="cell in item.cells">
                        <template v-for="textItem in cell.content">
                            <PortableText :value="textItem" :components="components" />
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts" setup>
import PtComponent from './PtComponent.vue';
import PtImage from './PtImage.vue';
import PtTable from './PtTable.vue';
import PtItemLink from './PtItemLink.vue';
import {
    PortableText,
    type PortableTextComponents,
    type PortableTextMarkComponent,
    type PortableTextTypeComponent,
    type PortableTextVueComponents,
} from "@portabletext/vue";

const props = defineProps({
    value: {
        type: Object,
        required: true
    }
});
// const props = defineProps<
//     PortableTextComponentProps<any>
// >();

const rows = ref<any>([]);
const headers = ref<any>([]);


const value = props.value
const components = {
    types: {
        component: PtComponent,
        image: PtImage,
        table: PtTable
    } as PortableTextTypeComponent,
    marks: {
        internalLink: PtItemLink,
        link: PtItemLink
    } as PortableTextMarkComponent

} as PortableTextVueComponents;

if (value?.rows) {

    for (let i = 0; i < value.rows.length; i++) {
        if (i == 0) {
            headers.value.push(value.rows[i]);
        }
        else {
            rows.value.push(value.rows[i]);
        }
    }
}
</script>