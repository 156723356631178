<template>
    <NuxtImg provider="kontentAiProvider" :src="imageUrl"></NuxtImg>
</template>
<script lang="ts" setup>
const props = defineProps({
    value: {
        type: Object,
        required: true
    }
});

//@ts-ignore
const imageUrl = props.value.asset.url
</script>