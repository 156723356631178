<template>
    <PortableText v-if="portableTextJson" :value="portableTextJson" :components="components"
        :onMissingComponent="onMissingComponent" />
</template>
<script setup lang="ts">

import PtComponent from './PtComponent.vue';
import PtImage from './PtImage.vue';
import PtTable from './PtTable.vue';
import PtItemLink from './PtItemLink.vue';
import { isEmpty } from '../../utils/kontent-helper'
import { transformToPortableText, nodeParse } from "@kontent-ai/rich-text-resolver"
import {
    PortableText,
    type PortableTextComponents,
    type PortableTextMarkComponent,
    type PortableTextTypeComponent,
    type PortableTextVueComponents,
} from "@portabletext/vue";
import PtSuperscript from './PtSuperscript.vue';

const config = useRuntimeConfig()
const props = defineProps<{ value: string | undefined }>();
const portableTextJson = ref<any>(null)
if (props.value && !isEmpty(props.value)) {
    try {
        portableTextJson.value = transformToPortableText(nodeParse(props.value))
    } catch (e) {
        console.error('Error parsing value', props.value)
        console.error('Error parsing JSON', e)
    }
    //portableTextJson.value = transformToPortableText(nodeParse(props.value))
}

const components = {
    types: {
        component: PtComponent,
        image: PtImage,
        table: PtTable
    } as PortableTextTypeComponent,
    marks: {
        internalLink: PtItemLink,
        link: PtItemLink,
        sup: PtSuperscript,
    } as PortableTextMarkComponent

} as PortableTextVueComponents;

const onMissingComponent = (message: string, options: any) => {
    if (config.public.isProduction) return;
    console.log(message, {
        // eg `someUnknownType`
        type: options.type,
        // 'block' | 'mark' | 'blockStyle' | 'listStyle' | 'listItemStyle'
        nodeType: options.nodeType,
    });
};

</script>