<template>
    <div class="home-page">
        <div v-if="!isEmpty(page.elements.bannerText.value)" class="banner-text text-center">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <RichText :value="page.elements.bannerText.value" />
                    </div>
                </div>
            </div>
        </div>

        <HomeHero v-if="page?.elements?.hero?.value?.length > 0"
            :model="pageStore.getLinkedItem<HomeHeroModel>(page.elements.hero.value[0])" />
        <AnchorNavigationBar />
        <RichText :value="page.elements.content.value" />
    </div>
</template>

<script lang="ts" setup>
import type { HomeHeroModel, WebSpotlightRootModel } from '~/models';
const pageStore = usePageStore();
const page = pageStore.page as WebSpotlightRootModel

const AnchorNavBarOrder = useState<string[]>('AnchorNavBarOrder')
AnchorNavBarOrder.value = page.elements.content.linkedItemCodenames;
</script>
