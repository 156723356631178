<template>
    <div v-if="useRuntimeConfig().public.enableSmartLinks" :data-kontent-item-id="itemId">
        <slot></slot>
    </div>
    <template v-else>
        <slot></slot>
    </template>
</template>
<script setup lang="ts">
const props = defineProps<{ itemId?: string }>()
const itemId = computed(() => props.itemId?.replace('_', ''))
</script>