<template>
  <SmartLinkItem v-if="data" :itemId="data?.system?.id">
    <component v-if="activeComponent" :is="activeComponent" />
  </SmartLinkItem>
</template>

<script setup lang="ts">
import HomePage from '~/components/pages/HomePage.vue';
import InteriorPage from '~/components/pages/InteriorPage.vue';
import { contentTypes } from '~/models'
import type { AnchorNavigationBarItem } from '~/types/AnchorNavigationBarItem';

const siteStore = useSiteStore()
const pageStore = usePageStore()
const route = useRoute()
const config = useRuntimeConfig()

let itemId = ''
if (route.query && !config.public.isProduction) {
  if (route.query.itemid) itemId = route.query?.itemid as string
}

const pageRouteInfo = siteStore.getPage(route.path, itemId)

const { data, error } = await useAsyncData(`page:${pageRouteInfo?.itemid}`, async () => {
  let pageData = null
  if (pageRouteInfo) {
    await pageStore.fetchData(pageRouteInfo.itemid)
    pageData = pageStore.page
  }
  return pageData
})
if (error.value) {
  console.error(error.value)
}

if (!data.value) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found'
  })
}

const navBarState = useState<AnchorNavigationBarItem[]>('navBarState');
navBarState.value = [];

const isInteriorPageState = useState<boolean>('isInteriorPage');
isInteriorPageState.value = false;
const activeComponent = resolveComponent(data.value?.system?.type)
const AnchorNavBarOrder = useState<string[]>('AnchorNavBarOrder')
AnchorNavBarOrder.value = [];
function resolveComponent(type: string) {
  switch (type) {
      case contentTypes.web_spotlight_root.codename:
          return HomePage
      case contentTypes.interior_page.codename:
          return InteriorPage
      default:
          return null
  }
}
</script>