export const useSmoothScroll = () => {
  const scrollToElement = (selector: string, offset = 0) => {
    const element = document.querySelector(replaceId(selector))
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY
      const headerOffset = offset
      const offsetPosition = elementPosition - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }

  return { scrollToElement }
}

function replaceId(id: string) {
  const noHash = id.replace('#', '')

  if (/^\d/.test(noHash)) {
    // If it does, escape the digit with '\\3' and a trailing space for CSS selector compliance
    return '#_' + noHash
  }
  let result = '#' + id

  while (result.startsWith('##')) {
    result = result.replace('##', '#')
  }

  return result
}
