<template>
    <SmartLinkItemComponent custom-tag="div" class="home-hero" v-if="model" :id="`_${model.system.id}`"
        :component="!model.system.workflow">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <SmartLinkStandaloneElement custom-tag="div" class="image" :codename="type.elements.image.codename">
                        <NuxtImg class="img-fluid" provider="kontentAiProvider"
                            v-if="firstOrDefault(model.elements.image.value)?.url"
                            :src="firstOrDefault(model.elements.image.value)?.url" />
                    </SmartLinkStandaloneElement>
                </div>
                <div class="col-lg-7">
                    <SmartLinkStandaloneElement custom-tag="h3" class="eyebrow"
                        :codename="type.elements.eyebrow.codename">
                        {{ model.elements.eyebrow.value }}
                    </SmartLinkStandaloneElement>
                    <SmartLinkStandaloneElement custom-tag="h1" class="title" :codename="type.elements.title.codename">
                        {{ model.elements.title.value }}
                    </SmartLinkStandaloneElement>
                    <SmartLinkStandaloneElement custom-tag="div" class="cta" :codename="type.elements.image.codename">
                        <CallToAction custom-class="btn btn-primary btn-lg"
                            v-if="model?.elements?.callToAction?.value?.length > 0"
                            :model="pageStore.getLinkedItem<CallToActionModel>(model.elements.callToAction.value[0])" />
                    </SmartLinkStandaloneElement>
                </div>
            </div>
        </div>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import { contentTypes, type CallToActionModel, type HomeHeroModel } from '~/models';

const pageStore = usePageStore();
const type = contentTypes.home_hero;
const props = defineProps<{ model: HomeHeroModel }>();

//console.log(props.model);
</script>