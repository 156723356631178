<template>
    <div class="interior-page">
        <InteriorHero v-if="page?.elements?.hero?.value?.length > 0"
            :model="pageStore.getLinkedItem<InteriorHeroModel>(page.elements.hero.value[0])" />
        <RichText :value="page.elements.content.value" />
    </div>
</template>

<script lang="ts" setup>
import type { InteriorHeroModel, InteriorPageModel } from '~/models';

const pageStore = usePageStore();
const page = pageStore.page as InteriorPageModel

const isInteriorPageState = useState<boolean>('isInteriorPage');

isInteriorPageState.value = true;
</script>
